import React, { FC, useState, useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useStore } from '@nanostores/react'
import Range from '@components/ui/Range'
import { monthMessage } from 'src/i18n/messages'

import { amount, duration, advancePay } from '../../store'
import { maxLeasingAmount } from '@src/config'

const priceRange = [100000, maxLeasingAmount]
const advancePartRange = [0, 49]
const durationRange = [6, 36]

// процент годовых, feraInterestRate
export const yearRate = 0.17
const monthlyRate = yearRate / 12

const vat = 0.2

const clamp = (num: number, [min, max]: number[]) =>
  Math.min(Math.max(num, min), max)

const Calculator: FC = () => {
  const { formatNumber, formatMessage } = useIntl()

  const [state, setState] = useState({
    price: amount.get(), // стоимость оборудования
    duration: duration.get(), // срок договора
    advancePart: 25, // процент авансового платежа
    advancePay: advancePay.get() // сумма авансового платежа
  })

  useEffect(() => {
    amount.set(state.price)
  }, [state.price])
  useEffect(() => {
    duration.set(state.duration)
  }, [state.duration])
  useEffect(() => {
    advancePay.set(state.advancePay)
  }, [state.advancePay])

  const storedAmount = useStore(amount)

  const leaseDepreciation =
    (((state.price / 120) * 100) / 12) * Math.min(12, state.duration)

  // сумма финансирования без НДС
  const finAmount = state.price - state.advancePay
  // комиссия
  const loanInterest = ((finAmount * yearRate) / 12) * state.duration

  // сумма договора
  const agreementAmount = state.price + loanInterest
  // ежемесячный платёж
  const monthlyPayment = (agreementAmount - state.advancePay) / state.duration

  const leaseInterest =
    (((state.price - state.advancePay) * yearRate) / 12) * state.duration

  const leaseInterestExpense = (leaseInterest / 120) * 100
  // экономия на налоге на прибыль
  const leaseIncomeTaxReduction =
    (leaseInterestExpense + leaseDepreciation) * 0.2
  // экономия на НДС
  const leaseVATReduction = ((state.price + leaseInterest) / 120) * 20

  // экономия на налогах
  const taxSavings = leaseVATReduction + leaseIncomeTaxReduction

  const handleUpdate = useCallback((name: string, value: number) => {
    setState((state) => {
      switch (name) {
        case 'price':
        case 'price-range':
          const price = clamp(value, priceRange)
          return {
            ...state,
            price,
            advancePay: Math.round((price * state.advancePart) / 100)
          }
        case 'advancePay':
          const advancePay = clamp(
            value,
            advancePartRange.map((n) => Math.round((state.price * n) / 100))
          )
          return {
            ...state,
            advancePay,
            advancePart: clamp(
              Math.round((value / state.price) * 100),
              advancePartRange
            )
          }
        case 'advancePart':
        case 'advancePart-range':
          const advancePart = clamp(value, advancePartRange)
          return {
            ...state,
            advancePart,
            advancePay: clamp(
              Math.round((state.price * advancePart) / 100),
              advancePartRange.map((n) => Math.round((state.price * n) / 100))
            )
          }
        case 'duration':
        case 'duration-range':
          return {
            ...state,
            duration: clamp(value, durationRange)
          }
        default:
          return state
      }
    })
  }, [])

  useEffect(() => {
    handleUpdate('price', storedAmount)
  }, [storedAmount, handleUpdate])

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.name) return
    let value: number
    value = parseInt(e.target.value.replace(/[^0-9]/g, '') || '0')

    handleUpdate(e.target.name, value)
  }

  const populateInput: React.FocusEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.name) return
    //@ts-ignore
    e.target.value = formatNumber(state[e.target.name])
  }

  const [formattedDuration, formattedDurationTitle] = formatMessage(
    monthMessage,
    { value: state.duration }
  ).split(' ')

  return (
    <div className='tabular-nums'>
      <div className='grid lg:grid-cols-3 md:grid-cols-2 lg:gap-10 gap-6 mb-8'>
        <div>
          <div className='text-p350 lg:text-p250 text-grayscale-150 mb-2'>
            Стоимость оборудования
          </div>
          <div className='flex'>
            <div className='font-display text-h300 font-medium mb-4 lg:mb-5 relative'>
              {formatNumber(state.price)}
              <input
                name='price'
                onChange={handleInputChange}
                onFocus={populateInput}
                className='absolute tabular-nums inset-0 font-display text-h300 font-medium p-0 w-full min-w-1/2 opacity-0 focus:opacity-100 focus:ring-0 border-0'
                type='text'
              />
              <span className='text-h350 font-normal'> ₽</span>
            </div>
          </div>
          <div className='flex pb-2'>
            <Range
              onChange={handleInputChange}
              name='price-range'
              min={priceRange[0]}
              max={priceRange[1]}
              value={state.price}
              className='flex-grow'
            />
          </div>
          <div className='flex justify-between text-p450 text-grayscale-200'>
            <div>{formatNumber(priceRange[0])} ₽</div>
            <div>{formatNumber(priceRange[1])} ₽</div>
          </div>
        </div>
        <div>
          <div className='text-p350 lg:text-p250 text-grayscale-150 mb-2'>
            Авансовый платёж
          </div>
          <div className='flex'>
            <div className='font-display relative text-h300 font-medium mb-4 lg:mb-5 advance-part'>
              {state.advancePart}
              <span className='text-h350 font-normal'> %</span>
              <input
                name='advancePart'
                onChange={handleInputChange}
                onFocus={populateInput}
                className='absolute tabular-nums inset-0 font-display text-h300 font-medium p-0 w-full opacity-0 focus:opacity-100 focus:ring-0 border-0'
                type='text'
              />
            </div>
            <div className='mx-4 w-1px bg-grayscale-300 h-6' />
            <div className='font-display relative min-w-1/2 text-h300 font-medium mb-4 lg:mb-5 advance-pay'>
              {formatNumber(state.advancePay)}
              <span className='text-h350 font-normal'> ₽</span>
              <input
                name='advancePay'
                onChange={handleInputChange}
                onFocus={populateInput}
                className='absolute tabular-nums inset-0 font-display text-h300 font-medium p-0 w-full opacity-0 focus:opacity-100 focus:ring-0 border-0'
                type='text'
              />
            </div>
          </div>
          <div className='flex pb-2'>
            <Range
              name='advancePart-range'
              onChange={handleInputChange}
              min={advancePartRange[0]}
              max={advancePartRange[1]}
              value={state.advancePart}
              className='flex-grow'
            />
          </div>
          <div className='flex justify-between text-p450 text-grayscale-200'>
            <div>{advancePartRange[0]} %</div>
            <div>{advancePartRange[1]} %</div>
          </div>
        </div>
        <div className='md:col-span-2 lg:col-span-1'>
          <div className='text-p350 lg:text-p250 text-grayscale-150 mb-2'>
            Срок лизинга
          </div>
          <div className='flex'>
            <div className='font-display relative min-w-1/2 text-h300 font-medium mb-4 lg:mb-5'>
              {formattedDuration}{' '}
              <span className='text-h350 font-normal'>
                {formattedDurationTitle}
              </span>
              <input
                name='duration'
                onChange={handleInputChange}
                onFocus={populateInput}
                className='absolute tabular-nums inset-0 font-display text-h300 font-medium p-0 w-full min-w-1/2 opacity-0 focus:opacity-100 focus:ring-0 border-0'
                type='text'
              />
            </div>
          </div>
          <div className='flex pb-2'>
            <Range
              name='duration-range'
              onChange={handleInputChange}
              min={durationRange[0]}
              max={durationRange[1]}
              value={state.duration}
              className='flex-grow'
            />
          </div>
          <div className='flex justify-between text-p450 text-grayscale-200'>
            <div>{formatMessage(monthMessage, { value: 6 })}</div>
            <div>{formatMessage(monthMessage, { value: 36 })}</div>
          </div>
        </div>
      </div>
      <div className='md:flex md:flex-wrap lg:flex-nowrap pb-2 border-t-1 border-grayscale-300'>
        <div className='flex w-auto lg:w-82 flex-none md:justify-between md:space-x-8 pt-8 md:pr-10 pb-7 lg:pb-0 md:border-r-1 border-grayscale-300'>
          <div className='flex-none mr-5 lg:mr-0'>
            <div className='lg:text-p250 text-p350 text-grayscale-150 mb-2'>
              Налоговая экономия
            </div>
            <div className='font-display text-h350'>
              до{' '}
              <span className='text-h300 font-medium tax-savings'>
                {formatNumber(Math.round(taxSavings))}
              </span>{' '}
              ₽
            </div>
          </div>
          <div className='flex-none'>
            <div className='lg:text-p250 text-p350 text-grayscale-150 mb-2'>
              Сумма договора
            </div>
            <div className='font-display text-h350'>
              <span className='text-h300 font-medium agreement-amount'>
                {formatNumber(Math.round(agreementAmount))}
              </span>{' '}
              ₽
            </div>
          </div>
        </div>
        <div className='pt-8 md:pl-10 flex-none border-t-1 md:border-t-0 border-grayscale-300'>
          <div className='lg:mr-10 mb-5 lg:mb-0'>
            <div className='tlg:text-p250 text-p350 text-grayscale-150 mb-2'>
              Ежемесячный платёж
            </div>
            <div className='font-display text-red-100 text-h350'>
              от{' '}
              <span className='text-h300 font-medium monthly-payment'>
                {formatNumber(Math.round(monthlyPayment))}
              </span>{' '}
              ₽
            </div>
          </div>
        </div>
        <p className='text-p450 text-grayscale-200 md:pt-8'>
          Данный расчёт не является публичной офертой. Точные параметры сделки
          вы получите, заполнив онлайн заявку.
        </p>
      </div>
    </div>
  )
}

export default Calculator
