import React, { FC, forwardRef, useRef, useEffect, memo } from 'react'

function syncProperties(el: HTMLInputElement) {
  el.style.setProperty('--val', el.value)
  el.style.setProperty('--min', el.min)
  el.style.setProperty('--max', el.max)
}

/**
 * This component is a polyfill for safari's range input.
 * There is no track fill in safari, so we have to use css background gradient to fill the track.
 * The background gradient relies on --val, --min and --max to be set.
 */
const Range = forwardRef<HTMLInputElement, React.ComponentPropsWithRef<'input'>>(function Range(props, ref) {
  const innerRef = useRef(null)

  // Wrapping passed ref to be able to access it in this component
  useEffect(() => {
    if (!ref) return
    if (typeof ref === 'function') {
      ref(innerRef.current)
    } else {
      ref.current = innerRef.current
    }
  })

  useEffect(() => {
    if (innerRef.current) {
      syncProperties(innerRef.current)
    }
  })

  return (
    <input
      ref={innerRef}
      type="range"
      {...props}
      onInput={(e) => {
        props.onInput && props.onInput(e)
        // @ts-ignore
        syncProperties(e.target)
      }}
    />
  )
})

export default memo(Range)